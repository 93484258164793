import { Component, OnInit } from '@angular/core';
import { UserManager } from 'oidc-client';
import {Event, RouterEvent, Router} from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';
// import { menus } from './sidebar-routes.config';
declare var $: any;
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    menuItems: any[] = [];
    private manager: UserManager = new UserManager(environment.authenSettings);
    constructor (
        private router: Router,
        private authService: AuthService,
        private apiService: ApiService,
    ) {
      
        router.events.pipe(
            filter((e: Event): e is RouterEvent => e instanceof RouterEvent)
         ).subscribe((e: RouterEvent) => {
             const pathname  = window.location.pathname ;
             this.parseObjectProperties(this.menuItems, e.url);
             this.menuItems = [...this.menuItems];
         });
        
    }

    ngOnInit() {
        const pathname  = window.location.pathname ;
        this.apiService.getListMenuByUserId(this.authService.getClaims().sub, '21F0D492-3BEE-41C8-A2D1-BAA32557D44B').subscribe(results => {
            this.menuItems = results.data.filter(menuItem => menuItem);
            localStorage.setItem('menuItems', JSON.stringify(results.data));
            this.parseObjectProperties(this.menuItems, pathname);
            this.menuItems = [...this.menuItems];
        });

        // menus.forEach((d: any) => {
        //     if(pathname.includes(d.code)) {
        //         d.styleClass= "parent_active"
        //     }else {
        //         d.styleClass= "parent_no_active"
        //     }
        // })
        // this.menuItems = menus

    }

    parseObjectProperties(obj: any[], pathname) {
        for (let k of obj) {
            k.label = k.title;
            if (k.path && k.classs !== 'navigation-header') {
                k.routerLink = k.path
            }
            if (k.submenus && k.submenus.length > 0) {
                k.items = k.submenus.filter((d: any) => d.classs && (d.classs.indexOf("hidden") < 0));
            }
            if (k.routerLink) {
                // active menu con
                if(k.isExternalLink) {
                    if (k.routerLink && pathname.includes(k.routerLink)) {
                        k.styleClass = 'parent_active'
                    } else {
                        k.styleClass = 'parent_no_active'
                    }
                }else {
                    if (k.routerLink && pathname.includes(k.routerLink)) {
                        k.styleClass = 'active'
                    } else {
                        k.styleClass = 'no-active'
                    }
                }
               
            } else {
                //active cha
                if (k.path && pathname && pathname.split('/').indexOf(k.path) > -1 && k.classs === 'navigation-header') {
                    k.styleClass = "parent_active"
                } else {
                    k.styleClass = "parent_no_active"
                }
            }

            if (k.hasOwnProperty('items') && Array.isArray(k.items) && k.items.length > 0) {
                this.parseObjectProperties(k.items, pathname);
            }
        }
    }


}
