import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
const apiBaseUrl = environment.apiBase;
const apiKSFBaseUrl = environment.apiKSFBase;
const apiInvestBase = environment.apiInvestBase;
const apiBondBase = environment.apiBondBase;
const apiInvplusBase = environment.apiInvplusBase;
const apiCoreBaseUrl = environment.apiCoreBase;
const apisreBase = environment.apisreBase
@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private authService: AuthService) { }

  options = {
    headers: new HttpHeaders({
      Authorization: this.authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
    })
  };

  apiTesst(): Observable<any> {
    return this.http
      .get<any>(`https://printer.sunshinegroup.vn:8080/api/printer`);
  }

  //Dơn vị tính

  getUnitPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/unit/GetPage?${queryParams}`, this.options);
  }

  getUnitInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/unit/GetInfo?${queryParams}`, this.options);
  }

  setUnitInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/unit/SetInfo`, queryParams, this.options);
  }

  delUnitInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v1/unit/Delete?${queryParams}`, this.options);
  }

  importUnit(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/unit/Import`, queryParams, {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
        'accept': '*/*',
      })
    });
  }


  // api GetMenu

  getSalerRevenuePage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/coresaler/GetSalerRevenuePage?${queryParams}`, this.options);
  }

  getRevenueDashboard(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/coresaler/GetRevenueDashboard?${queryParams}`, this.options);
  }

  // saler

  getSalerKPIPage(api, queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/coresaler/${api}?${queryParams}`, this.options);
  }
  getSalerBonusPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/coresaler/GetSalerBonusPage?${queryParams}`, this.options);
  }

  setIncomSaler(url, queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/coresaler/${url}`, queryParams, {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
        'accept': '*/*',
      })
    });
  }

  delIncomSalerKpi(url,queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v1/coresaler/${url}?${queryParams}`, this.options);
  }

  // Tra cứu thư viện

  getDocumentPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/coresaler/GetDocumentPage?${queryParams}`, this.options);
  }

  getRevenueCustPage(url,queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/coresaler/${url}?${queryParams}`, this.options);
  }

  getDocument(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/coresaler/GetDocument?${queryParams}`, this.options);
  }

  setDocument(params: any): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/coresaler/SetDocument`, params, this.options);
  }

  delDocument(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v1/coresaler/DelDocument?${queryParams}`, this.options);
  }


  
  // Đạt lệnh Invest

  getOrderBookPage(loaiDauTu = 'invest',queryParams: any): Observable<any> {
    const baseUrl = (() => {
      if (loaiDauTu === 'invest') {
        return apiInvestBase;
      } else if (loaiDauTu === 'invest-plus') {
        return apiInvplusBase;
      }

      return apiBondBase;
    })();
    return this.http.get(`${baseUrl}/api/v2/book/GetOrderBookPage?${queryParams}`, this.options);
  }

  getOrderBookInfo(loaiDauTu = 'invest',queryParams: any): Observable<any> {
    const baseUrl = (() => {
      if (loaiDauTu === 'invest') {
        return apiInvestBase;
      } else if (loaiDauTu === 'invest-plus') {
        return apiInvplusBase;
      }
      
      return apiBondBase;
    })();
    return this.http.get(`${baseUrl}/api/v2/book/GetOrderBookInfo?${queryParams}`, this.options);
  }

  setOrderBookDraft(loaiDauTu = 'invest',queryParams: any): Observable<any> {
    const baseUrl = (() => {
      if (loaiDauTu === 'invest') {
        return apiInvestBase;
      } else if (loaiDauTu === 'invest-plus') {
        return apiInvplusBase;
      }
      
      return apiBondBase;
    })();
    return this.http.post(`${baseUrl}/api/v2/book/SetOrderBookDraft`, queryParams, this.options);
  }
  
  setOrderBookInfo(loaiDauTu = 'invest',queryParams: any): Observable<any> {
    const baseUrl = (() => {
      if (loaiDauTu === 'invest') {
        return apiInvestBase;
      } else if (loaiDauTu === 'invest-plus') {
        return apiInvplusBase;
      }
      
      return apiBondBase;
    })();
    return this.http.post(`${baseUrl}/api/v2/book/SetOrderBookInfo`, queryParams, this.options);
  }

  delOrderBook(loaiDauTu = 'invest',queryParams: any): Observable<any> {
    const baseUrl = (() => {
      if (loaiDauTu === 'invest') {
        return apiInvestBase;
      } else if (loaiDauTu === 'invest-plus') {
        return apiInvplusBase;
      }
      
      return apiBondBase;
    })();
    return this.http.delete(`${baseUrl}/api/v2/book/DelOrderBook?${queryParams}`, this.options);
  }

  setOrderBookStatus(loaiDauTu = 'invest',params: any): Observable<any> {
    const baseUrl = (() => {
      if (loaiDauTu === 'invest') {
        return apiInvestBase;
      } else if (loaiDauTu === 'invest-plus') {
        return apiInvplusBase;
      }
      
      return apiBondBase;
    })();
    return this.http.post(`${baseUrl}/api/v2/book/SetOrderBookStatus`, params, this.options);
  }
  
  getOpenList(loaiDauTu = 'invest',queryParams: any): Observable<any> {
    const baseUrl = (() => {
      if (loaiDauTu === 'invest') {
        return apiInvestBase;
      } else if (loaiDauTu === 'invest-plus') {
        return apiInvplusBase;
      }
      
      return apiBondBase;
    })();

    switch (loaiDauTu) {
      case 'invest':
        return this.http.get<any>(`${baseUrl}/api/v2/open/GetOpenList?` + queryParams, this.options);
      case 'invest-plus':
        return this.http.get<any>(`${baseUrl}/api/v2/open/GetOpenList?` + queryParams, this.options);
      case 'bond':
        return this.http.get<any>(`${baseUrl}/api/v1/opensale/GetOpenSaleList?` + queryParams, this.options);
      default:
        return this.http.get<any>(`${baseUrl}/api/v1/open/GetOpenList?` + queryParams, this.options);
    }
  }

  getOpenDetail(loaiDauTu = 'invest',queryParams): Observable<any> {
    const baseUrl = (() => {
      if (loaiDauTu === 'invest') {
        return apiInvestBase;
      } else if (loaiDauTu === 'invest-plus') {
        return apiInvplusBase;
      }
      
      return apiBondBase;
    })();

    if(loaiDauTu === 'invest' || loaiDauTu === 'invest-plus') {
      return this.http.get<any>(`${baseUrl}/api/v2/open/GetOpenDetail?` + queryParams, this.options);
    }else {
      return this.http.get<any>(`${baseUrl}/api/v1/opensale/GetOpenSaleInfo?` + queryParams, this.options);
    }
  }
  getRegSaleInfo(): Observable<any> {
    return this.http.get(`${apiCoreBaseUrl}/api/v1/coresaler/GetRegSaleInfo`, this.options);
  }

  getAccountList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiInvestBase}/api/v2/account/getAccountList?` + queryParams, this.options);
}

  // api Core
  getListMenuByUserId(userId, webId): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/ClientMenuGetListByUserId?` +
      `userId=${userId}&webId=${webId}`, this.options)
  }

  getAccountListCore(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/getAccountList?` + queryParams, this.options);
  }

  getKhachHang(queryParams, type): Observable<any> {
    if (type == 0) {
      return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustIndiPage?` + queryParams, this.options);
    } else {
      return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coporate/GetCustCopoPage?` + queryParams, this.options);
    }
  }

  getManagerList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetManagerList?` + queryParams, this.options);
  }

  setWorkSubmit(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/customer/SetWorkSubmit`, params, this.options);
  }

  removeCustProfile(queryParams): Observable<any> {
    return this.http
      .delete<any>(`${apiCoreBaseUrl}/api/v1/customer/RemoveCustProfile?` + queryParams, this.options)
  }

  searchCustomer(queryParams): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustIndividualPage?` + queryParams, this.options);
  }

  setCustIndiIdentityCreate(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustIndiIdentityCreate`, params, this.options);
  }

  getCustIndiCreate(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustIndiCreate`, params, this.options);
  }

  getBankList(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/GetBankList`, this.options);
  }

  setCustIndiCreate(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustIndiCreate`, params, this.options);
  }

  getCustIndiFields(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustIndiFields?` + queryParams, this.options)
  }

  getIdentityCardInfomation(image: File) {
    const options = {
      headers: new HttpHeaders({
        'key': 'WZWfWCN2VPDxbYsV6sRfR0N1fV8x030h'
      })
    };
    const formdata = new FormData();
    formdata.append('image', image, 'TanTano');
    formdata.append('request_id', '14071996');
    return this.http.post('https://api.cloudekyc.com/v3.2/ocr/recognition', formdata, options);
  }
  
  setCustIndiIdentity(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustIndiIdentity`, params, this.options);
  }

  setCustUser(params): Observable<any> {
    return this.http
      .post<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustUser`, params, this.options);
  }

  getCustIndiIdentity(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustIndiIdentity?` + queryParams, this.options)
  }

  setCustIndiFields(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustIndiFields`, params, this.options);
  }
  
  getAccountPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/GetAccountPage?` + queryParams, this.options)
  }

  getCustMetas(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustMetas?` + queryParams, this.options)
  }

  deleteCustUser(params): Observable<any> {
    return this.http
      .delete<any>(`${apiCoreBaseUrl}/api/v1/individual/DeleteCustUser?` + params, this.options);
  }

  setCustIndiIdentityDefault(params) {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustIndiIdentityDefault`, params, this.options)
  }

  delCustAddressContact(queryParams) {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/individual/DelCustAddressContact?` + queryParams, this.options)
  }

  delCustIndiIdentity(queryParams) {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/individual/DelCustIndiIdentity?` + queryParams, this.options)
  }

  setAccountStatus(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/SetAccountStatus`, params, this.options)
  }

  getCustProfileUserPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustProfileUserPage?` + queryParams, this.options)
  }
  
  setProfileIdcardVerify(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coreuser/SetProfileIdcardVerify`, params, this.options);
  }

  setProfileInfo(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coreuser/SetProfileInfo`, params, this.options);
  }

  getProfileInfo(queryParams): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coreuser/GetProfileInfo?` + queryParams, this.options)
  }

  geAddressList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/corelogin/GeAddressList?` + queryParams, this.options)
  }

  setCustAddressContact(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustAddressContact`, params, this.options);
  }

  getAccountInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/GetAccountInfo?` + queryParams, this.options)
  }

  setAccountInfo(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/SetAccountInfo`, params, this.options)
  }

  getProfileByReferral(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreuser/GetProfileByReferral?` + queryParams, this.options);
  }

  getCustObjectListNew(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustObjectList?` + queryParams, this.options);
  }

  getObjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiInvestBase}/api/v2/work/GetObjectList?` + queryParams, this.options);
  }

  exportReportLocalhost(url): Observable<Blob> {
    return this.http.get(url, {
      responseType: "blob"
    });
  }

  // hang thanh vien

  GetPriorityCustomerList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/GetPriorityCustomerList?` + queryParams, this.options);
  }

  GetPriorityCustomerDetail(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/GetPriorityCustomerDetail?` + queryParams, this.options);
  }

  getObjectListInUser(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/user/GetObjectList?` + queryParams, this.options);
  }

  getCooperatorList(): Observable<any> {
    return this.http.get(`${apiInvplusBase}/api/v2/cooperator/GetCooperatorList`, this.options);
  }

  getSalerKSFPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/coresaler/GetSalerKSFPage?` + queryParams, this.options);
  }

  getSalerInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetSalerInfo?` + queryParams, this.options);
  }

  getRegionList(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetRegionList`, this.options);
  }

  getAgencyOrganizeMap(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetAgencyOrganizeMap`, this.options);
  }

  getSalerOrganizeMap(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetSalerOrganizeMap`, this.options);
  }

  getKSFSalerOrganizeMap(): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/coresaler/GetKSFSalerOrganizeMap`, this.options);
  }

  getSalerPositions(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetSalerPositions?` + queryParams, this.options)
  }
  
  getSalerTypes(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetSalerTypes`, this.options);
  }

  getAgencyOrganizeList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/getAgencyOrganizeList?` + queryParams, this.options);
  }

  getProjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${apisreBase}/api/v1/shousing/GetProjectList?` + queryParams, this.options);
  }
  
  addRoomSaler(params): Observable<any> {
    return this.http.put<any>(`${apisreBase}/api/v2/ksfinance/AddRoomSaler`, params, this.options);
  }

  getAgentLeaders(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/getAgentLeaders?` + queryParams, this.options);
  }

  delRoomSaler(queryParams): Observable<any> {
    return this.http.put<any>(`${apisreBase}/api/v2/ksfinance/DelRoomSaler`, queryParams , this.options);
  }

  getRoomBySalerId(queryParams): Observable<any> {
    return this.http.get<any>(`${apisreBase}/api/v2/ksfinance/GetRoomBySalerId?` + queryParams, this.options);
  }

  getBuildingList(queryParams): Observable<any> {
    return this.http.get<any>(`${apisreBase}/api/v1/shousing/GetBuildingList?` + queryParams, this.options);
  }

  getRoomPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apisreBase}/api/v2/ksfinance/GetRoomPage?` + queryParams, this.options);
  }
  
  // kh trai nghiem
  GetCustomerByCifNo(cifNo, type, classificatio?): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/GetCustomerByCifNo?cifNo=${cifNo}&type=${type}&classificatio=${classificatio}`, this.options);
  }

  GetExperiencePriorityCustomerManagePage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/GetExperiencePriorityCustomerManagePage?` + queryParams, this.options);
  }

  GetExperiencePriorityCustomer(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/GetExperiencePriorityCustomer?` + queryParams, this.options);
  }

  CreateExperiencePriorityCustomer(queryParams): Observable<any> {
    return this.http.post<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/CreateExperiencePriorityCustomer`, queryParams, this.options);
  }

  UpdateExperiencePriorityCustomer(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/UpdateExperiencePriorityCustomer`, queryParams, this.options);
  }

  DeleteExperiencePriorityCustomer(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/DeleteExperiencePriorityCustomer?` + queryParams, this.options);
  }

  UpdateStatusExperiencePriorityCustomer(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/UpdateStatusExperiencePriorityCustomer`, queryParams, this.options);
  }

  GetRoleSalerByCustId(): Promise<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/GetRoleSalerByCustId`, this.options).toPromise();
  }
  // ket thuc

  // Quản lý ngoại lệ TnC Offline
  GetExceptionRequestPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/GetExceptionRequestPage?` + queryParams, this.options);
  }

  GetExceptionRequestHistoryPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/GetExceptionRequestHistoryPage?` + queryParams, this.options);
  }

  GetPriorityCustomerExceptionRequest(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/GetPriorityCustomerExceptionRequest?` + queryParams, this.options);
  }

  ReviewExceptionRequest(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/ReviewExceptionRequest`, queryParams, this.options);
  }

  DeleteExceptionRequest(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/DeleteExceptionRequest?` + queryParams, this.options);
  }

  SetExceptionRequest(queryParams): Observable<any> {
    return this.http.post<any>(`${apiKSFBaseUrl}/api/v1/priority-customer/SetExceptionRequest`, queryParams, this.options);
  }
  // end

  // quản lý cộng tác viên
  GetSalerOrganizeForSSalerMap(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/coreagent/GetSalerOrganizeForSSalerMap?` + queryParams, this.options);
  }
  GetSalerPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/coreagent/GetSalerPage?` + queryParams, this.options);
  }

  /* ---------- Báo cáo cho saler ----------*/
  // Báo cáo KH
  GetReportOfCustomerForSaler(queryParams): Observable<any> {
    // @ts-ignore
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/report/GetReportOfCustomerForSaler?` + queryParams, {...this.options, responseType: 'blob'});
  }

  // Báo cáo kinh doanh ngày
  GetDailyReportForSaler(queryParams): Observable<any> {
    // @ts-ignore
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/report/GetDailyReportForSaler?` + queryParams, {...this.options, responseType: 'blob'});
  }

  // https://dev.api.core.unicloudgroup.com.vn/swagger/index.html#!/CoreAgent/CoreAgent_GetSalerOrganizeForSSalerMap
  // https://dev.api.core.unicloudgroup.com.vn/swagger/index.html#!/CoreAgent/CoreAgent_GetSalerPage

  // end

  GetUserRole(userId): Observable<any> {
    return this.http.get<any>(`${apiBaseUrl}/api/v1/user/GetUserCategories?userId=${userId}`, this.options)
  }

  
  GetManagerInfo(userId, webId): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetManagerInfo?userId=${userId}&webId=${webId}`, this.options);
  }

  // thay doi tk
  ChangeBankAccRequestGetPage(queryParams){
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/exception-task/ChangeBankAccOrderPage?` + queryParams, this.options);
  }

  ChangeBankAccRequestGet(queryParams){
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/exception-task/ChangeBankAccOrderGet?` + queryParams, this.options);
  }

  ChangeBankAccRequestDel(queryParams){
    return this.http.delete<any>(`${apiKSFBaseUrl}/api/v1/exception-task/ChangeBankAccOrderDel?` + queryParams, this.options);
  }

  ChangeBankAccRequestSet(queryParams){
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v1/exception-task/ChangeBankAccOrderSet`, queryParams, this.options);
  }

  ChangeBankAccRequestApprove(queryParams){
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v1/exception-task/ChangeBankAccOrderApprove`, queryParams, this.options);
  }
  
  ChangeBankAccRequestSearchOrder(queryParams){
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v1/exception-task/ChangeBankAccOrderSearch?` + queryParams, this.options);
  }

  GetKsbAccountFetch(accountNumber, bankCode) {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/GetKsbAccountFetch?accountNumber=${accountNumber}&bankCode=${bankCode}`, this.options);
  }

  // doi tk bank ksinvest

  ChangeBankAccPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiInvestBase}/api/v2/account/ChangeBankAccPage?` + queryParams, this.options);
  }

  ChangeBankAccGet(queryParams): Observable<any> {
    return this.http.get<any>(`${apiInvestBase}/api/v2/account/ChangeBankAccGet?` + queryParams, this.options);
  }

  ChangeBankAccDel(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiInvestBase}/api/v2/account/ChangeBankAccDel?` + queryParams, this.options);
  }

  ChangeBankAccAdd(queryParams): Observable<any> {
    return this.http.post<any>(`${apiInvestBase}/api/v2/account/ChangeBankAccAdd`, queryParams, this.options);
  }

  ChangeBankAccUpDate(queryParams): Observable<any> {
    return this.http.put<any>(`${apiInvestBase}/api/v2/account/ChangeBankAccUpDate`,queryParams, this.options);
  }

  ChangeBankAccApprove(queryParams): Observable<any> {
    return this.http.put<any>(`${apiInvestBase}/api/v2/account/ChangeBankAccApprove?`,queryParams, this.options);
  }

  ChangeBankAccOrderSearch(queryParams): Observable<any> {
    return this.http.get<any>(`${apiInvestBase}/api/v2/account/ChangeBankAccOrderSearch?` + queryParams, this.options);
  }

  ChangeBankAccCheckOrdExists(queryParams): Observable<any> {
    return this.http.get<any>(`${apiInvestBase}/api/v2/account/ChangeBankAccCheckOrdExists?` + queryParams, this.options);
  }

  // InvPlus
  ChangeBankAccPageInvPlus(queryParams): Observable<any> {
    return this.http.get<any>(`${apiInvplusBase}/api/v2/account/ChangeBankAccPage?` + queryParams, this.options);
  }

  ChangeBankAccGetInvPlus(queryParams): Observable<any> {
    return this.http.get<any>(`${apiInvplusBase}/api/v2/account/ChangeBankAccGet?` + queryParams, this.options);
  }

  ChangeBankAccDelInvPlus(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiInvplusBase}/api/v2/account/ChangeBankAccDel?` + queryParams, this.options);
  }

  ChangeBankAccAddInvPlus(queryParams): Observable<any> {
    return this.http.post<any>(`${apiInvplusBase}/api/v2/account/ChangeBankAccAdd`, queryParams, this.options);
  }

  ChangeBankAccUpDateInvPlus(queryParams): Observable<any> {
    return this.http.put<any>(`${apiInvplusBase}/api/v2/account/ChangeBankAccUpDate`,queryParams, this.options);
  }

  ChangeBankAccApproveInvPlus(queryParams): Observable<any> {
    return this.http.put<any>(`${apiInvplusBase}/api/v2/account/ChangeBankAccApprove?`,queryParams, this.options);
  }

  ChangeBankAccOrderSearchInvPlus(queryParams): Observable<any> {
    return this.http.get<any>(`${apiInvplusBase}/api/v2/account/ChangeBankAccOrderSearch?` + queryParams, this.options);
  }

  ChangeBankAccCheckOrdExistsInvPlus(queryParams): Observable<any> {
    return this.http.get<any>(`${apiInvplusBase}/api/v2/account/ChangeBankAccCheckOrdExists?` + queryParams, this.options);
  }
    // end
}