const host = {
  socketServer: 'https://chat.sunshineservice.vn:3001',
  authServer: 'https://dev-auth.unicloudgroup.com.vn', 
  apiServer: 'https://dev.api.unicloudgroup.com.vn', 
  apiKSFServer: 'https://dev.api.ksf.unicloudgroup.com.vn', 
  apiCoreServer: 'https://dev.api.core.unicloudgroup.com.vn', 
  uploadServer: 'https://api.data.ksfinance.co/api/v1/FileHandler', 
  apiInvestServer: 'https://dev.api.invest.unicloudgroup.com.vn', 
  apiBondServer: 'https://dev.api.bond.unicloudgroup.com.vn', 
  apiInvpluserver: 'https://dev.api.invest.plus.unicloudgroup.com.vn', 
  apisreServer: 'https://dev.api.sre.unicloudgroup.com.vn',
  // cloudFunction: 'https://us-central1-sunshine-super-app.cloudfunctions.net' //dev
  cloudFunction: 'https://asia-northeast1-sunshine-app-production.cloudfunctions.net' //product
};

const authenSettings = {
  authority: host.authServer,
  client_id: 'web_ks_saler_prod',
  redirect_uri: 'https://dev-web-saler.unicloudgroup.com.vn/auth-callback',
  post_logout_redirect_uri: 'https://dev-web-saler.unicloudgroup.com.vn/',
  response_type: 'id_token token',
  scope: 'openid profile api_sre api_ksfinance_app api_core_bigtec api_invest_bigtec api_bond_bigtec api_invplus_bigtec ',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  silent_redirect_uri: 'https://dev-web-saler.unicloudgroup.com.vn/silent-refresh.html'

};


const firebase = {
  apiKey: 'AIzaSyAb3orVc8nnGT0L2JgbdzXrRND393mFiFU',
  authDomain: 'sunshine-app-production.firebaseapp.com',
  databaseURL: 'https://sunshine-app-production.firebaseio.com',
  projectId: 'sunshine-app-production',
  storageBucket: 'sunshine-app-production.appspot.com'
  // apiKey: 'AIzaSyAczqJoNnTDPPLktoPtQ694IH38sR8wX6w',
  // authDomain: 'sunshine-super-app.firebaseapp.com',
  // databaseURL: 'https://sunshine-super-app.firebaseio.com',
  // projectId: 'sunshine-super-app',
  // storageBucket: 'sunshine-super-app.appspot.com',
  // messagingSenderId: '504497996884',
  // appId: '1:504497996884:web:e07732b704e759529819c1',
};

export const environment = {
  production: true,
  apiBase: host.apiServer,
  apiKSFBase: host.apiKSFServer,
  apisreBase: host.apisreServer,
  apiInvestBase: host.apiInvestServer,
  apiInvplusBase: host.apiInvpluserver,
  apiBondBase: host.apiBondServer,
  apiCoreBase: host.apiCoreServer,
  socketServer: host.socketServer,
  cloudFunctionServer: host.cloudFunction,
  authenSettings: authenSettings,
  uploadServer: host.uploadServer,
  firebase
};









