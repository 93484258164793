import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthCallbackComponent } from "./auth-callback/auth-callback.component";
import { DefaultLayoutComponent } from "./containers/default-layout/default-layout.component";
import { HomeComponent } from "./pages/home/home.component";
import { AuthGuardService } from "./services/auth-guard.service";
// Import Containers
export const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  { path: 'auth-callback', component: AuthCallbackComponent },
  {
    path: '', component: DefaultLayoutComponent,
    data: { title: '' }, children: [
      { path: 'home', component: HomeComponent },
      {
        path: 'can-bo-tu-van',
        loadChildren: () => import('../app/pages/danh-cho-can-bo-tu-van/danh-cho-can-bo-tu-van.module').then(m => m.DanhChoCanBoTuVanModule)
      },
      {
        path: 'bao-cao-doanh-so',
        loadChildren: () => import('../app/pages/bao-cao-doanh-so/bao-cao-doanh-so.module').then(m => m.BaoCaoDoanhSoModule)
      },
      {
        path: 'thu-vien',
        loadChildren: () => import('../app/pages/thu-vien-tra-cuu/thu-vien-tra-cuu.module').then(m => m.ThuVienTraCuuModule)
      },
      {
        path: 'dat-lenh',
        loadChildren: () => import('../app/pages/dat-lenh/dat-lenh.module').then(m => m.DatLenhModule)
      },
      {
        path: 'quan-ly-khach-hang-vip',
        loadChildren: () => import('../app/pages/quan-ly-khach-hang-vip/quan-ly-khach-hang-vip.module').then(m => m.QuanLyKhachHangVipModule)
      },
      {
        path: 'sale',
        loadChildren: () => import('../app/pages/quan-ly-sale/quan-ly-sale.module').then(m => m.QuanLySaleModule)
      },
      {
        path: 'quan-ly-khach-hang-trai-nghiem',
        loadChildren: () => import('../app/pages/quan-ly-khach-hang-trai-nghiem/quan-ly-khach-hang-trai-nghiem.module').then(m => m.QuanLyKhachHangTraiNghiemModule)
      },
      {
        path: 'quan-ly-ngoai-le-tnc',
        loadChildren: () => import('../app/pages/quan-ly-ngoai-le-tnc/quan-ly-ngoai-le-tnc.module').then(m => m.QuanLyNgoaiLeTncModule)
      },
      {
        path: 'quan-ly-nang-hang-truoc-ky',
        loadChildren: () => import('../app/pages/quan-ly-nang-hang-truoc-ky/quan-ly-nang-hang-truoc-ky.module').then(m => m.QuanLyNangHangTruocKyModule)
      },
      {
        path: 'quan-ly-dinh-danh-truoc-ky',
        loadChildren: () => import('../app/pages/quan-ly-dinh-danh-truoc-ky/quan-ly-dinh-danh-truoc-ky.module').then(m => m.QuanLyDinhDanhTruocKyModule)
      },
      {
        path: 'tra-cuu',
        loadChildren: () => import('../app/pages/tra-cuu-chuyen-doi/tra-cuu-chuyen-doi.module').then(m => m.TraCuuChuyenDoiModule)
      },
      {
        path: 'thay-doi-tk',
        loadChildren: () => import('../app/pages/quan-ly-thay-doi-tk/thay-doi-tk.module').then(m => m.ThayDoiTKModule)
      },
    ], canActivate: [AuthGuardService]
  },
  
  // {
  //   path: 'pos', component: DefaultLayoutPosComponent,
  //   data: { title: '' }, children: [
  //     { path: 'home', component: HomeComponent },
  //     {
  //       path: 'danh-muc',
  //       loadChildren: () => import('../app/components/danh-muc/danh-muc.module').then(m => m.DanhMucModule)
  //     },
     
  //   ], canActivate: [AuthGuardService]
  // }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
